
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';

const START_POS_X = 250;
const START_POS_Y = 50;
const HORIZONTAL_SPACING = 200; // Adjust this value as needed

const getTransformationNodes = (draft, connectedNodes = [], unconnectedNodes = []) => {
    const { source, transformationMap = {} } = draft;
    const numberOfTransformations = (connectedNodes.length + unconnectedNodes.length);
    const nodes = [{
        id: source.id,
        type: 'customSource',
        sourcePosition: "right",
        data: { label: source.name },
        connectable: true,
        deletable: false,
        position: transformationMap?.positions?.[source.id] || { x: START_POS_X, y: START_POS_Y },
        style: {  backgroundColor: `white`, fontSize: '12px', border: "none"},
    }, ...connectedNodes.map((transformation, i) => ({
        id: transformation.id,
        type: transformation?.type === "DECISION" ? "DECISION" : "customTransformation",
        sourcePosition: "right",
        targetPosition: "left",
        data: { label: transformation.name },
        connectable: true,
        position: transformationMap?.positions?.[transformation.id] || { x: START_POS_X + (i + 1) * HORIZONTAL_SPACING, y: START_POS_Y },
        style: { backgroundColor: `white`, fontSize: '12px', padding: "10px", border: "none" }
    })), ...unconnectedNodes.map((transformation, i) => ({
        id: transformation.id,
        type: transformation?.type === "DECISION" ? "DECISION" : "customTransformation",
        sourcePosition: "right",
        targetPosition: "left",
        data: { label: transformation.name },
        connectable: true,
        position: transformationMap?.positions?.[transformation.id] || { x: START_POS_X + 400 + (i + 1) * HORIZONTAL_SPACING, y: START_POS_Y },
        style: { backgroundColor: `white`, fontSize: '12px', padding: "10px", border: "none" }
    })), ...draft.destinations.map((destination, i) => ({
        id: destination.id,
        type: 'customTarget',
        targetPosition: "left",
        data: { label: destination.name },
        position: transformationMap?.positions?.[destination.id] || { x: START_POS_X + 400 + (i + 1) * HORIZONTAL_SPACING, y: START_POS_Y },
        style: {  backgroundColor: `white`, fontSize: '12px', border: "none"},
    }))];
    return nodes;
}

const processConnectionMap = (data) => {
    const edges = [], connectedNodes = [], unconnectedNodes = [];

    const { transformations = [], transformationMap = {} } = data;
    const { edgeMap = [] } = transformationMap;
    edgeMap.forEach(edge => {
        //are source and target nodes valid for this edge
        if ((data.source.id === edge.source || transformations.find(elem => elem.id === edge.source)) && (data.destinations.find(elem => elem.id === edge.target) || transformations.find(elem => elem.id === edge.target))) {
            edges.push({
                id: edge.source + edge.target,
                source: edge.source,
                target: edge.target,
                type: 'buttonedge',
                data: { label: edge.label },
                sourceHandle: transformations.find(elem => elem.id === edge.source && elem.type === "DECISION") !== undefined ? edge.label.toString() : null
            })
        }
    });

    forEach(transformations, transformation => {
        if (filter(edges, edge => edge.source === transformation.id || edge.target === transformation.id).length > 0) {
            connectedNodes.push(transformation);
        } else {
            unconnectedNodes.push(transformation);
        }
    });
    return {
        edges, connectedNodes, unconnectedNodes
    };
}

export {
    getTransformationNodes,
    processConnectionMap
}