import { Fragment, useCallback } from "react";
import PageTitle from "../../../components/Nav/PageTitle";
import { useNavigate } from "react-router-dom";
import { breadcrumbs } from "..";
import CreateFunction from "./CreateFunction";
import ContextHelp from "../../../components/ContextHelp";

const NewFunction = () => {
    const navigate = useNavigate();

    const onSuccess = useCallback((data) => {
        navigate(`/functions/${data.id}`);
    }, [navigate]);

    const onCancel = useCallback(() => {
        navigate('/functions');
    }, [navigate]);

    return <Fragment>
        <PageTitle itemKey="sources" label="New Function" breadcrumbs={breadcrumbs} />
        <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="mt-5 md:col-span-2 md:mt-0">
                <CreateFunction onSuccess={onSuccess} onCancel={onCancel} />
            </div>
            <div className="md:col-span-1">
                <ContextHelp page="create-function" />
            </div>
        </div>
    </Fragment>
}

export default NewFunction;