module.exports = {

	decisionBlockScript: `
package main

import (
	"encoding/json"
	"fmt"

	"github.com/hashicorp/go-plugin"
	"github.com/zinscky/shared"
)

// You can define your own struct based on what
// event data you are expecting.
type MyEvent struct {
	Sub       string 
	Processed bool   
}

// Here is a real implementation of Decision
type DecisionImpl struct{}

// Write your implementation here. The args paramter is a struct which contains the following
//
//	type Args struct {
//		Event string //json string which you can unmarshal into your own struct
//		Config map[string]string //key/value configuration that you added when creating a new transformation
//		Log log.Logger //logger instance. Basic methods are provided. Debug, Info, Warn and Error
//		DecisionResp bool // true or false response for your decision block
//	}
func (t *DecisionImpl) Execute(args shared.Args) (shared.Args, error) {
	args.Log.Info("incoming event %s", args.Event)
	if args.Event == "" {
		return args, fmt.Errorf("nil event")
	}
	// Unmarshal event json into your own type
	myEvent := &MyEvent{}
	err := json.Unmarshal([]byte(args.Event), myEvent)
	if err != nil {
		return args, err
	}
	// Create your desicion condition
	if myEvent.Sub == "" {
		args.DecisionResp = false
	} else {
		args.DecisionResp = true
	}
	// always return the args with your decision response
	return args, nil
}

// DO NOT EDIT BELOW CODE
var handshakeConfig = plugin.HandshakeConfig{
	ProtocolVersion:  1,
	MagicCookieKey:   "BASIC_PLUGIN",
	MagicCookieValue: "hello",
}

func main() {
	runner := &DecisionImpl{}
	var pluginMap = map[string]plugin.Plugin{
		"{{runner}}": &shared.DecisionPlugin{Impl: runner},
	}
	plugin.Serve(&plugin.ServeConfig{
		HandshakeConfig: handshakeConfig,
		Plugins:         pluginMap,
	})
}

	`,

    modScriptTemplate:
        `
module plugintemplate

go 1.22

require (
	github.com/hashicorp/go-plugin v1.6.2
	github.com/zinscky/shared v1.4.1
)

require (
	github.com/fatih/color v1.7.0 // indirect
	github.com/golang/protobuf v1.5.3 // indirect
	github.com/hashicorp/go-hclog v0.14.1 // indirect
	github.com/hashicorp/yamux v0.1.1 // indirect
	github.com/mattn/go-colorable v0.1.4 // indirect
	github.com/mattn/go-isatty v0.0.17 // indirect
	github.com/oklog/run v1.0.0 // indirect
	github.com/zinscky/log v1.1.0 // indirect
	golang.org/x/net v0.17.0 // indirect
	golang.org/x/sys v0.13.0 // indirect
	golang.org/x/text v0.13.0 // indirect
	google.golang.org/genproto/googleapis/rpc v0.0.0-20230711160842-782d3b101e98 // indirect
	google.golang.org/grpc v1.58.3 // indirect
	google.golang.org/protobuf v1.31.0 // indirect
)


`,
    mainScriptTemplate:
        `
package main

import (
	"encoding/json"
	"fmt"

	"github.com/hashicorp/go-plugin"
	"github.com/zinscky/shared"
)

// You can define your own struct based on what
// event data you are expecting.
type MyEvent struct {
	Sub       string 
	Processed bool   
}

// Here is a real implementation of Transformation
type TransformationImpl struct{}

// Write your implementation here. The args paramter is a struct which contains the following
//
//	type Args struct {
//		Event string //json string which you can unmarshal into your own struct
//		Config map[string]string //key/value configuration that you added when creating a new transformation
//		Log log.Logger //logger instance. Basic methods are provided. Debug, Info, Warn and Error
//	}
func (t *TransformationImpl) Execute(args shared.Args) (shared.Args, error) {
	args.Log.Info("incoming event %s", args.Event)
	if args.Event == "" {
		return args, fmt.Errorf("nil event")
	}
	// Unmarshal event json into your own type
	myEvent := &MyEvent{}
	err := json.Unmarshal([]byte(args.Event), myEvent)
	if err != nil {
		return args, err
	}
	// Do some transformation on yhour event data
	myEvent.Processed = true

	// marshal your event and update in args
	eventJson, err := json.Marshal(myEvent)
	if err != nil {
		return args, err
	}
	args.Event = string(eventJson)

	// always return the args
	return args, nil
}

// DO NOT EDIT BELOW CODE
var handshakeConfig = plugin.HandshakeConfig{
	ProtocolVersion:  1,
	MagicCookieKey:   "BASIC_PLUGIN",
	MagicCookieValue: "hello",
}

func main() {
	runner := &TransformationImpl{}
	var pluginMap = map[string]plugin.Plugin{
		"{{runner}}": &shared.TransformationPlugin{Impl: runner},
	}
	plugin.Serve(&plugin.ServeConfig{
		HandshakeConfig: handshakeConfig,
		Plugins:         pluginMap,
	})
}

`,
    mainScriptDestinationTemplate:
        `
package main

import (
	"encoding/json"
	"fmt"

	"github.com/hashicorp/go-plugin"
	"github.com/zinscky/shared"
)

// You can define your own struct based on what
// event data you are expecting.
type MyEvent struct {
	Sub       string
	Processed bool  
}

// Here is a real implementation of Destination
type DestinationImpl struct{}

// Write your implementation here. The args paramter is a struct which contains the following
//
//	type Args struct {
//		Event string //json string which you can unmarshal into your own struct
//		Config map[string]string //key/value configuration that you added when creating a new transformation
//		Log log.Logger //logger instance. Basic methods are provided. Debug, Info, Warn and Error
//	}
func (t *DestinationImpl) Execute(args shared.Args) (shared.Args, error) {
	args.Log.Info("incoming event %s", args.Event)
	if args.Event == "" {
		return args, fmt.Errorf("nil event")
	}
	// Unmarshal event json into your own type
	myEvent := &MyEvent{}
	err := json.Unmarshal([]byte(args.Event), myEvent)
	if err != nil {
		return args, err
	}
	// Send to destination

	// always return the args
	return args, nil
}

// DO NOT EDIT BELOW CODE
var handshakeConfig = plugin.HandshakeConfig{
	ProtocolVersion:  1,
	MagicCookieKey:   "BASIC_PLUGIN",
	MagicCookieValue: "hello",
}

func main() {
	runner := &DestinationImpl{}
	var pluginMap = map[string]plugin.Plugin{
		"{{runner}}": &shared.DestinationPlugin{Impl: runner},
	}
	plugin.Serve(&plugin.ServeConfig{
		HandshakeConfig: handshakeConfig,
		Plugins:         pluginMap,
	})
}

`,
	extractorScriptTemplate: `


	package main

	import (
		"encoding/json"
		"github.com/hashicorp/go-plugin"
		"github.com/zinscky/shared"
	)
	
	type MyData struct {
		Sub string
		CharCount int
	}
	
	// Here is a real implementation of Extractor
	type ExtractorImpl struct{}
	
	// Write your implementation here. The args paramter is a struct which contains the following
	//
	//	type Args struct {
	//		Config map[string]string //key/value configuration that you added when creating a new transformation
	//		Log log.Logger //logger instance. Basic methods are provided. Debug, Info, Warn and Error
	//		Data string // your extracted data as a json string
	//	}
	func (ex *ExtractorImpl) Execute(args shared.ExtractorArgs) (shared.ExtractorArgs, error) {
		args.Log.Info("executing extractor")
	
		// Extract your data. make sure your data is in the form of a slice/array
		myData := []interface{}{}
		myData = append(myData, MyData{Sub: "abc"})
		myData = append(myData, MyData{Sub: "abcd"})
		// Convert your data into json string and Assign to argument data
		data, err := json.Marshal(myData)
		if err != nil {
			return args, err
		}
		args.Data = string(data)
	
		// always return the args
		return args, nil
	}
	
	// DO NOT EDIT BELOW CODE
	var handshakeConfig = plugin.HandshakeConfig{
		ProtocolVersion:  1,
		MagicCookieKey:   "BASIC_PLUGIN",
		MagicCookieValue: "hello",
	}
	
	func main() {
		runner := &ExtractorImpl{}
		var pluginMap = map[string]plugin.Plugin{
			"{{runner}}": &shared.ExtractorPlugin{Impl: runner},
		}
		plugin.Serve(&plugin.ServeConfig{
			HandshakeConfig: handshakeConfig,
			Plugins:         pluginMap,
		})
	}
	
		
	`

}
