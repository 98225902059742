import { navItems } from "../data";
import find from 'lodash/find';
import { IconExclamationCircle, IconCopy } from '@tabler/icons-react';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const PageTitle = (props) => {
    const navItem = find(navItems, ['key', props.itemKey])
    return <div>
        <Helmet>
            <title>{props.label || navItem.label} | cnips</title>
        </Helmet>
        {props.breadcrumbs && <div>
            <div className="text-sm breadcrumbs flex items-center">
                <ul>
                    {
                        props.breadcrumbs?.map(item => <li key={item.label}><Link to={item.url}>{item.label}</Link></li>)
                    }
                    <li>{props.label || navItem.label}</li>
                </ul>
            </div>
        </div>}
        <div className="flex justify-between mb-4">
            <div className="flex items-center flex-1">
                <navItem.Icon size={36} />
                <div className="text-xl transition-all duration-200 md:text-3xl font-bold ml-2">{props.label || navItem.label}</div>
                {props.error &&
                    <div className="tooltip" data-tip={`${props.errorMessage}`}>
                        <IconExclamationCircle
                            className="ml-2 text-error"
                            size={36}
                            strokeWidth={1.5}
                        />
                    </div>
                }
                {props.clone && <button
                    className="ml-auto btn  btn-primary btn-sm md:btn-md"
                    onClick={props.clone}
                >
                    <IconCopy
                        size={18}
                        strokeWidth={2}
                        color={'white'}
                        className="m-0 p-0"
                    />
                    Clone
                </button>}
            </div>
            <div>
                {props.children}
            </div>
        </div></div>
}

export default PageTitle;