import Editor from "@monaco-editor/react";
import { useState } from "react";
import { generateWorkspacePath } from "./urlHelpers";
import axios from 'axios';
import useNotifications from "../hooks/useNotifications";
import get from 'lodash/get';

const TestScriptRun = ({ event, onCancel, onSendEvent }) => {

    const [body, setBody] = useState("{}");
    const [isValidJson, setIsValidJson] = useState(true);
    const workspacePath = generateWorkspacePath();
    const [response, setResponse] = useState({})
    const { addNotification } = useNotifications();

    const validateJson = (value) => {
        try {
            JSON.parse(value);
            setIsValidJson(true);
        } catch {
            setIsValidJson(false);
        }
    };

    const handleEditorChange = (value) => {
        setBody(value);
        validateJson(value);
    };

    const triggerScriptRun = async () => {
        try {
            const parsedData = JSON.parse(body);
            const response = await axios.post(`${workspacePath}/${event.type}/${event.id}/test`, {
                body: parsedData
            });
            if (response && response.data && response.status === 200) {
                setResponse(response.data);
            }
        } catch (error) {
            addNotification({
                message: get(error, 'response.data.message', 'An error occurred while triggering Transformation script'),
                type: 'error',
            });
        }
    };

    return <div>
        <div className="space-y-3 px-4 py-5 sm:p-6">
            <div className="w-full">
                <label className="label">
                    <span className="label-text">Payload</span>
                </label>
                <div className="mx-1"><Editor
                    theme="vs-dark"
                    height="120px"
                    options={{ 'fontSize': 15, quickSuggestions: false, scrollBeyondLastLine: false, readOnly: false, minimap: { enabled: true } }}
                    defaultLanguage="json"
                    value={body}
                    onChange={handleEditorChange}
                /></div>
            </div>
            {event.type !== "destinations" && <div className="w-full">
                <label className="label">
                    <span className="label-text">Response</span>
                </label>
                <div className="mx-1"><Editor
                    theme="vs-dark"
                    height="120px"
                    options={{ 'fontSize': 15, quickSuggestions: false, scrollBeyondLastLine: false, readOnly: true, minimap: { enabled: true } }}
                    defaultLanguage="json"
                    value={JSON.stringify(response.response, null, 2)}
                /></div>
            </div>}
            <div className="w-full">
                <label className="label">
                    <span className="label-text">Logs</span>
                </label>
                <div className="mx-1"><Editor
                    theme="vs-dark"
                    height="120px"
                    options={{ 'fontSize': 15, quickSuggestions: false, scrollBeyondLastLine: false, readOnly: true, minimap: { enabled: true } }}
                    defaultLanguage="json"
                    value={JSON.stringify(response.logs, null, 2)}
                /></div>
            </div>
        </div>
        <div className="bg-base-200 px-4 py-3 justify-between sm:px-6 flex">
            <button className="btn" disabled={!isValidJson} onClick={() => triggerScriptRun()}>Run Script</button>
            <button className="btn btn-primary" onClick={onCancel}>Close</button>
        </div>
    </div>
}

export default TestScriptRun;