import axios from "axios";
import { useEffect, useState } from "react";
import get from 'lodash/get';
import useNotifications from "../../../../hooks/useNotifications";
import { generateWorkspacePath } from "../../../../common/urlHelpers";
import Loading from "../../../../components/Loading";
import { IconFileDescription, IconRefresh } from "@tabler/icons-react";
import LogDetailsModal from "./LogDetails";
import Pagination from "../../../../components/Pagination";
import LogFilter from "./LogFilter";


const EventLogs = ({ sourceId }) => {
    const workspacePath = generateWorkspacePath();
    const [logs, setLogs] = useState(false)
    const [loading, setLoading] = useState(true);
    const { addNotification } = useNotifications();
    const [details, showDetails] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [status, setStatus] = useState(null)
    const failedNotification = (err) => {
        addNotification({
            message: get(err, 'response.data.message', 'An error occurred while fetching Logs'),
            type: 'error'
        });
    }


    const logUrl = () => {
        let url = new URL(`${workspacePath}/extractors/logs/sources/${sourceId}`, axios.defaults.baseURL)
        if (currentPage != 0) {
            url.searchParams.set("page", currentPage)
        }
        if (status != null) {
            url.searchParams.set("status", status)
        }
        return url.toString()
    }

    const getLogs = async () => {
        setLogs(false)
        const url = logUrl()
        axios.get(url)
            .then(res => setLogs(res.data))
            .catch(err => failedNotification(err))
            .finally(setLoading(false))
    }

    useEffect(() => {
        setLoading(true);
        getLogs()
    }, [currentPage, status])

    return (
        <div>

            <div className="mb-4 mt-4 text-lg font-bold flex justify-between items-center">
                <div className="mb-4 text-lg font-bold">Extractor Logs</div>
                <span>
                    <button disabled={loading} className="btn btn-ghost gap-2" onClick={getLogs}>
                        <IconRefresh size={24} />Refresh
                    </button>
                </span>
            </div>
            <div>
                <LogFilter status={status} setStatus={setStatus} />
            </div>
            {/* <div>Hello {sourceId} {JSON.stringify(logs)}</div> */}
            <div className="table-container">
                <table className="table data-table table-zebra w-full">

                    <thead>
                        <tr>
                            <th className="bg-base-300 text-left w-1/3 md:w-1/3">Event ID</th>
                            <th className="bg-base-300 text-left">Received At</th>
                            <th className="bg-base-300 text-left">Status</th>
                            <th className="bg-base-300 text-left">Execution Time (ms)</th>
                            <th className="bg-base-300 text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody style={{ maxHeight: '600px' }}></tbody>
                    {
                        logs.list && logs.list.map(event => (
                            <tr key={event.id}>
                                <td className="whitespace-pre-wrap break-words">{event.id}</td>
                                <td className="whitespace-pre-wrap break-words">{event.createdAt}</td>
                                <td className="whitespace-pre-wrap break-words">{event.state}</td>
                                <td className="whitespace-pre-wrap break-words">{event.executionTime}</td>
                                <div className="join">
                                    <button onClick={() => showDetails(event)} className="btn btn-sm join-item btn-ghost tooltip" data-tip="Show Details"><IconFileDescription size={24} /></button>
                                </div>
                            </tr>
                        ))
                    }
                    {logs == false && <tr><td colSpan="5" className="text-center"><Loading /></td></tr> }
                </table>
            </div>
            {logs.count > 0 && <Pagination totalCount={logs.count} pageSize={15} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
            {details && <LogDetailsModal showDetails={showDetails} details={details} />}
        </div>

    )
}

export default EventLogs