import { IconPlus, IconX } from "@tabler/icons-react";
import pluralize from "pluralize";
import { useState } from "react";
import ConfirmModal from "../../../../components/ConfirmModal";

const DestinationList = (props) => {
    const { selectDestination, destinations = [], unlinkDestination } = props;
    const [deleteDestinationId, setDeleteDestinationId] = useState(null);

    return <div className="overflow-x-hidden overflow-y-scroll break-all">
        <table className="table table-zebra w-full">
            <thead>
                <tr>
                    <th>Destinations</th>
                    <th className="text-center">
                        <button className="btn btn-sm btn-accent btn-square" onClick={selectDestination}>
                            <IconPlus width={32} />
                        </button>
                    </th>
                </tr>
            </thead>
            <tbody>
                {destinations.map(destination => {
                    return <tr key={destination.id}>
                        <td>{destination.name}</td>
                        <td className="text-center">
                            <button className="btn btn-sm btn-ghost btn-square" onClick={() => setDeleteDestinationId(destination.id)}>
                                <IconX width={32} />
                            </button>
                        </td>
                    </tr>
                })}
                {destinations.length === 0 && <tr><td colSpan={2}>No associated destinations</td></tr>}
            </tbody>
            {destinations.length > 0 && <tfoot>
                <tr>
                    <th colSpan={2}>{pluralize('destination', destinations.length, true)}</th>
                </tr>
            </tfoot>}
        </table>
        {deleteDestinationId && <ConfirmModal title="Are you sure?"
            message="Are you sure you want to remove the destination? Pipeline mappings must be updated to ensure data is correctly processed."
            onConfirm={() => { unlinkDestination(deleteDestinationId); setDeleteDestinationId(null); }} onCancel={() => setDeleteDestinationId(null)} />}
    </div>
}

export default DestinationList;