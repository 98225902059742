import React, { memo } from "react";
import { Handle, Position } from "reactflow";

export default memo(({ data, isConnectable }) => {
  return (
    <div style={{ textAlign: 'center', position: 'relative', padding: '10px', border: '1px solid black', borderRadius: '4px' }}>
      <img src="/data-transformation.svg" alt="node" style={{ width: '50px', height: '50px', borderRadius: '4px' }} />
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        style={{
          top: '50%',
          left: '-5px',
          background: 'black',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          transform: 'translateY(-50%)'
        }}
      />
      <div style={{
        position: 'absolute',
        bottom: '-26px',
        left: '50%',
        transform: 'translateX(-50%)',
        whiteSpace: 'nowrap'
      }}>
        {data.label}
      </div>
      <Handle
        type="source"
        position={Position.Right}
        id="true"
        style={{
          top: 20, background: 'black',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          transform: 'translateY(-50%)'
        }}
        isConnectable={isConnectable}
      ><div className="handletext">true</div></Handle>
      <Handle
        type="source"
        position={Position.Right}
        id="false"
        style={{
          bottom: 10, top: "auto", background: 'black',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          transform: 'translateY(-50%)'
        }}
        isConnectable={isConnectable}
      ><div className="handletext">false</div></Handle>
    </div>
  );
});
