import { ReactFlowProvider } from "reactflow";
import PipelineView from "./PipelineView";
import TransformationList from "./TransformationList";
import filter from 'lodash/filter';
import { useRef, useState } from "react";
import axios from "axios";
import useNotifications from "../../../../hooks/useNotifications";
import ItemSelectorModal from "../../../../components/ItemSelectorModal";
import get from 'lodash/get';
import { IconArrowBackUp, IconDeviceFloppy } from "@tabler/icons-react";
import { generateWorkspacePath } from "../../../../common/urlHelpers";
import uuid from 'react-native-uuid';
import DestinationList from "./DestinationList";

/**
 * Ensure that there are edges connecting from source to destination node
 */
const isPipelineValid = (data, edgeMap, transformations, draft) => {
    const { source } = data;
    const destinations = draft?.destinations || []; // Handle multiple destinations
    const visitedNodes = {};
    let currentNode = source.id;
    let isValid = false;

    while (currentNode && !visitedNodes[currentNode]) {
        // Check if currentNode is one of the destinations
        if (destinations.some(destination => currentNode === destination.id)) {
            isValid = true; // Valid if we reach any destination
            break;
        }

        visitedNodes[currentNode] = true;
        const currentNodeEdges = filter(edgeMap, { source: currentNode });

        // Find the node's transformation type
        const transformation = transformations.find(t => t.id === currentNode);
        const type = transformation?.type;

        // Validate edges based on the node type
        if (type === "DECISION") {
            if (currentNodeEdges.length !== 2) {
                break; // Decision nodes must have exactly two edges
            }
        } else {
            if (currentNodeEdges.length !== 1) {
                break; // Non-decision nodes must have exactly one edge
            }
        }

        // Proceed to the next node in the edge
        const currentEdge = currentNodeEdges[0];
        currentNode = currentEdge?.target;
    }

    return isValid;
};




const PipelineEditor = (props) => {
    const ref = useRef();
    const { id, draft, data, setDraft, setData, discardChanges } = props;
    const { addNotification } = useNotifications();
    const [showTranformationSelector, setShowTransformationSelector] = useState(false);
    const [showDestinationSelector, setShowDestinationSelector] = useState(false);
    const [type, setType] = useState("");
    const workspacePath = generateWorkspacePath();
    const onSelectTransformation = (item) => {
        linkTransformation(item.id, item.name, item.type);
    }

    const onSelectDestination = (item) => {
        linkDestination(item.id, item.name)
    }

    const selectTransformation = (type) => {
        setType(type);
        setShowTransformationSelector(true);
    }

    const selectDestination = () => {
        setShowDestinationSelector(true);
    }

    const linkTransformation = (id, name, type) => {
        const transformations = draft?.transformations || [];
        const nodeMap = draft?.nodeMap || {};
        var nodeId = uuid.v1();
        if (filter(transformations, { id: nodeId }).length === 0) {
            const updatedNodeMap = {
                ...nodeMap,
                [nodeId]: id
            };

            setDraft(current => ({
                ...current,
                transformations: [
                    ...transformations,
                    { id: nodeId, name, type }
                ],
                nodeMap: updatedNodeMap
            }))
        } else {
            addNotification({
                message: 'The selected transformation is already in use in this Pipeline',
                type: 'error'
            });
        }
        setShowTransformationSelector(false);
    }

    const linkDestination = (id, name) => {
        const destinations = draft?.destinations || [];
        const nodeMap = draft?.nodeMap || {};
        var nodeId = uuid.v1();
        if (filter(destinations, { id: nodeId }).length === 0) {
            const updatedNodeMap = {
                ...nodeMap,
                [nodeId]: id
            };

            setDraft(current => ({
                ...current,
                destinations: [
                    ...destinations,
                    { id: nodeId, name, type: "DESTINATION" }
                ],
                nodeMap: updatedNodeMap
            }))
        } else {
            addNotification({
                message: 'The selected transformation is already in use in this Pipeline',
                type: 'error'
            });
        }
        setShowDestinationSelector(false);
    }

    const unlinkTransformation = (id) => {
        const transformations = draft?.transformations || [];
        setDraft(current => ({
            ...current,
            transformations: transformations.filter(e => e.id !== id)
        }))
    }

    const cleanNodeMap = (draft) => {
        const { nodeMap, source, destinations, transformations } = draft;

        // Helper function to check if a key exists in any section
        const isKeyInSections = (key) => {
            // Check if key matches source ID or apiAccessRef
            if (source.id === key || source.apiAccessRef === key) return true;

            // Check if key matches any destination ID
            if (destinations.some(destination => destination.id === key)) return true;

            // Check if key matches any transformation ID
            if (transformations.some(transformation => transformation.id === key)) return true;

            // Key not found in any section
            return false;
        };

        // Iterate over nodeMap and remove keys that don't exist in any section
        for (const key in nodeMap) {
            if (!isKeyInSections(key) && !isKeyInSections(nodeMap[key])) {
                delete nodeMap[key];
            }
        }
    }

    const unlinkDestination = (id) => {
        const destinations = draft?.destinations || [];
        setDraft(current => ({
            ...current,
            destinations: destinations.filter(e => e.id !== id)
        }))
    }

    const savePipeline = (e) => {
        const transformationMap = ref.current.getCurrentTransformMap();
        const workspacePath = generateWorkspacePath();
        cleanNodeMap(draft);
        if (!transformationMap) {
            addNotification({
                message: 'The pipeline does not have a valid route to destination. Changes are not saved.',
                type: 'error'
            });
            return;
        }
        if (!isPipelineValid(data, transformationMap.edgeMap, draft.transformations, draft)) {
            addNotification({
                message: 'The pipeline does not have a valid route to destination. Changes are not saved.',
                type: 'error'
            });
            return;
        }
        const payload = { transformationMap, transformations: draft.transformations, nodeMap: draft.nodeMap, id: data.id, active: data.active, source: data.source, destinations: draft.destinations, workspaceId: data.workspaceId };
        axios.put(`${workspacePath}/pipelines/${id}`, payload)
            .then(response => {
                setData(response.data);
                setDraft(response.data)
                addNotification({
                    message: 'The Pipeline changes have been saved.',
                    type: 'success'
                });
            }).catch(err => {
                addNotification({
                    message: get(err, 'response.data.message', 'An error occurred while updating the Pipeline'),
                    type: 'error'
                });
            })
    }

    return <div className="grid lg:grid-cols-12 md:grid-cols-3 grid-cols-2 gap-2">
        <ReactFlowProvider>
            <div className="xl:col-span-9 lg:col-span-8 md:col-span-2 col-span-1" style={{ width: '100%', minHeight: 'calc(100vh - 250px)', height: '100%' }}>
                <PipelineView ref={ref} draft={draft} />
            </div>
        </ReactFlowProvider>
        <div className="xl:col-span-3 lg:col-span-4 md:col-span-1 col-span-1 overflow-x-scroll">
            <div className="my-2 flex justify-center">
                <div className="join">
                    <button onClick={discardChanges} className="btn join-item"><IconArrowBackUp size={24} />Reset</button>
                    <button onClick={savePipeline} className="btn btn-primary join-item"><IconDeviceFloppy size={24} />Update</button>
                </div>
            </div>
            <TransformationList type={"TRANSFORMATION"} selectTransformation={selectTransformation} transformations={draft.transformations} unlinkTransformation={unlinkTransformation} />
            <TransformationList type={"DECISION"} selectTransformation={selectTransformation} transformations={draft.transformations} unlinkTransformation={unlinkTransformation} />
            <DestinationList selectDestination={selectDestination} destinations={draft.destinations} unlinkDestination={unlinkDestination} />
        </div>
        {showTranformationSelector && <ItemSelectorModal url={workspacePath + "/transformations?type=" + type} title={"Associate" + type === "TRANSFORMATION" ? "Transformations" : "Decision Blocks"} cols={[{ "label": "Name", "datacss": "text-left", "css": "w-3/4", "name": "name" }, { "label": "Active", "datacss": "", "css": "text-center", "name": "active" }]}
            onCancel={() => setShowTransformationSelector(false)} onSelect={onSelectTransformation} />}
        {showDestinationSelector && <ItemSelectorModal url={workspacePath + "/destinations"} title="Associate Destinations" cols={[{ "label": "Name", "datacss": "text-left", "css": "w-3/4", "name": "name" }, { "label": "Active", "datacss": "", "css": "text-center", "name": "active" }]}
            onCancel={() => setShowDestinationSelector(false)} onSelect={onSelectDestination} />}
    </div>
}

export default PipelineEditor;