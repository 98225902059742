import ListFilter from "../../../../components/ListFilter";



export const extractorStatusList = [
    {
        label: 'None',
        value: null
    },
    {
        label: 'Processing',
        value: 'Processing'
    },
    {
        label: 'Completed',
        value: 'Completed'
    },
    {
        label: 'Failed',
        value: 'Failed'
    }
]

const LogFilter = (props) => {
    return <ListFilter {...props} statusList={extractorStatusList} />
}

export default LogFilter;