import { Editor } from "@monaco-editor/react"
import Modal from "../../../../components/Modal"

const LogDetailsModal = ({ details, showDetails, }) => {
    return (<Modal title="Logs" onCancel={() => { showDetails(false) }}>
        <div className="space-y-3 px-4 py-5 sm:p-6">

            <div className="mx-1">
                <Editor
                    theme="vs-dark"
                    height="220px"
                    options={{ 'fontSize': 15, quickSuggestions: false, scrollBeyondLastLine: false, readOnly: true, minimap: { enabled: false } }}
                    defaultLanguage="json"
                    value={JSON.stringify(details.consoleLog, null, 2)}
                />
            </div>
        </div>
    </Modal>)
}

export default LogDetailsModal