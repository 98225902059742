import { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../components/Nav/PageTitle";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useNotifications from "../../../hooks/useNotifications";
import get from 'lodash/get';
import { renderErrors } from "../../../common/formHelpers";
import { breadcrumbs } from "..";
import ContextHelp from "../../../components/ContextHelp";
import { generateWorkspacePath } from "../../../common/urlHelpers";

const NewConfiguration = () => {
    const navigate = useNavigate();
    const { addNotification } = useNotifications();
    const [name, setName] = useState('');
    const [key, setKey] = useState('');
    const [value, setValue] = useState('');
    const [description, setDescription] = useState('');
    const [clientId, setClientId] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [reqScopes, setReqScopes] = useState('');
    const [wellKnownUrl, setWellKnownUrl] = useState('');
    const [totpKey] = useState('');
    const [totp_placement, settotp_placement] = useState('header');
    const [totp_placeholder, settotp_placeholder] = useState('');
    const [apiKey] = useState('');
    const [apikey_placement, setapikey_placement] = useState('header');
    const [apikey_placeholder, setapikey_placeholder] = useState('');
    const [type, setType] = useState('APIAccess');
    const [apiAccessType, setApiAccessType] = useState('CIDAAS_OAUTH2')
    const [active, setActive] = useState(true);
    const [executing, setExecuting] = useState(false);
    const [error, setError] = useState({ message: null, details: [] });
    const [fetchingCopyContents, setFetchingCopyContents] = useState(false)
    const workspacePath = generateWorkspacePath();
    var location = useLocation();


    const resetAll = () => {
        setName('');
        setType('APIAccess');
        setApiAccessType('CIDAAS_OAUTH2');
        setKey('');
        setValue('');
        setDescription('');
        setActive(true);
        clearErrors();
    }

    const resetType = () => {
        setApiAccessType('CIDAAS_OAUTH2');
        setKey('');
        setValue('');
    }

    const resetAPIAccessType = () => {
        setapikey_placeholder('');
        settotp_placeholder('');
        settotp_placement('header');
        setapikey_placement('header');
        setWellKnownUrl('')
        setClientId('')
        setClientSecret('')
    }

    const clearErrors = () => {
        setError({ message: null, details: [] });
    }

    const submit = (e) => {
        e.preventDefault();
        clearErrors();
        setExecuting(true);

        var apiAccess = {};

        if (type === "APIAccess") {
            switch (apiAccessType) {
                case 'CIDAAS_OAUTH2':
                    apiAccess = {
                        apiAccessType: apiAccessType,
                        oAuthDetails: {
                            client_id: clientId,
                            client_secret: clientSecret,
                            req_scopes: reqScopes,
                            wellknownUrl: wellKnownUrl

                        }
                    }
                    break;
                case 'GEN_OAUTH2':
                    apiAccess = {
                        apiAccessType: apiAccessType,
                        oAuthDetails: {
                            client_id: clientId,
                            client_secret: clientSecret,
                            req_scopes: reqScopes,
                            wellknownUrl: wellKnownUrl

                        }
                    }
                    break;
                case 'APIKEY':
                    apiAccess = {
                        apiAccessType: apiAccessType,
                        apiKeyDetails: {
                            apiKey: apiKey,
                            apikey_placeholder: apikey_placeholder,
                            apikey_placement: apikey_placement
                        }
                    }
                    break;
                case 'TOTP':
                    apiAccess = {
                        apiAccessType: apiAccessType,
                        totpDetails: {
                            totpKey: totpKey,
                            totp_placeholder: totp_placeholder,
                            totp_placement: totp_placement
                        }
                    }
                    break;
            }
        }

        var payload = {
            name,
            description,
            key,
            value,
            active,
            type,
            apiAccess

        };
        axios.post(`${workspacePath}/configurations`, payload).then(response => {
            resetAll();
            addNotification({
                message: 'Configuration has been created',
                type: 'success'
            });
            navigate('/configurations');
        }).catch(err => {
            let message = 'An error occurred while creating Configuration';
            if (err.status === 409) {
                message = "A Configuration with this name already exists. Please try with a different name.";
            }
            addNotification({
                message: get(err, 'response.data.message', message),
                type: 'error'
            });
            setError(err.response.data);
        }).finally(() => {
            setExecuting(false);
        })
    };




    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const cloneFrom = queryParams.get("cloneFrom")
        if (cloneFrom) {
            setExecuting(true)
            setFetchingCopyContents(true)

            addNotification(
                {
                    type: 'info',
                    message: 'Fetching data...'
                }
            )
            axios.get(`${workspacePath}/configurations/${cloneFrom}`).then(response => {


                setName(`${response.data.name}_clone`)
                setDescription(response.data.description)
                setKey(response.data.key)
                setValue(response.data.value)
                setActive(response.data.active)
                setKey(response.data.key)

                if (response.data.type === "KeyValue") {
                    setType("KeyValue")
                    setKey(response.data.key)
                    setValue(response.data.value)
                }

                if (response.data.apiAccess) {
                    switch (response.data.apiAccess.apiAccessType) {
                        case 'CIDAAS_OAUTH2':
                            setClientId(response.data.apiAccess.oAuthDetails.client_id)
                            setClientSecret(response.data.apiAccess.oAuthDetails.client_secret)
                            setReqScopes(response.data.apiAccess.oAuthDetails.req_scopes)
                            setWellKnownUrl(response.data.apiAccess.oAuthDetails.wellKnownUrl)
                            break;
                        case 'GEN_OAUTH2':

                            setClientId(response.data.apiAccess.oAuthDetails.clientId)
                            setClientSecret(response.data.apiAccess.oAuthDetails.client_secret)
                            setReqScopes(response.data.apiAccess.oAuthDetails.req_scopes)
                            setWellKnownUrl(response.data.apiAccess.oAuthDetails.wellKnownUrl)

                            break;
                        case 'APIKEY':
                            setapikey_placement(response.data.apiAccess.apiKeyDetails.apikey_placeholder)
                            setapikey_placeholder(response.data.apiAccess.apiKeyDetails.apikey_placement)
                            break;
                        case 'TOTP':
                            settotp_placeholder(response.data.apiAccess.totpDetails.totp_placeholder)

                            break;
                    }
                }




                addNotification({
                    type: "success",
                    message: "Configuration cloned successfully."
                })
            }).catch(err => {
                console.log(err)
                addNotification({
                    message: get(err, 'response.data.message', 'An error occurred while fetching Configuration'),
                    type: 'error'
                });
            }).finally(() => {
                setExecuting(false)
                setFetchingCopyContents(false)
            })
        }
    }, [location]);


    return <Fragment>
        <PageTitle itemKey="configurations" label="New Configuration" breadcrumbs={breadcrumbs} />
        <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="mt-5 md:col-span-2 md:mt-0">
                <form onSubmit={submit}>
                    <div className="shadow-inner sm:overflow-hidden sm:rounded-md">
                        <div className="space-y-3 px-4 py-5 sm:p-6">

                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">Name</span>
                                </label>
                                <input type="text" placeholder="Provide a name for the Configuration" value={name} className="input input-bordered w-full" onChange={e => setName(e.target.value)} />
                                {renderErrors(error, 'name')}
                            </div>
                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">Description</span>
                                </label>
                                <input type="text" placeholder="Provide a description" value={description} className="input input-bordered w-full" onChange={e => setDescription(e.target.value)} />
                                {renderErrors(error, 'description')}
                            </div>

                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">Select Configuration Type</span>
                                </label>
                                <div className="input-group">
                                    <select value={type} onChange={e => { setType(e.target.value); resetType() }} className="input input-bordered w-full">
                                        <option value="APIAccess">API Access</option>
                                        <option value="KeyValue">Key Value</option>
                                    </select>
                                </div>
                                {renderErrors(error, 'type')}
                            </div>
                            {type === "APIAccess" ? (
                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text">Select API Access Type</span>
                                    </label>
                                    <div className="input-group">
                                        <select value={apiAccessType} onChange={e => { setApiAccessType(e.target.value); resetAPIAccessType() }} className="input input-bordered w-full">
                                            <option value="CIDAAS_OAUTH2">cidaas OAuth2</option>
                                            <option value="APIKEY">API key</option>
                                            <option value="GEN_OAUTH2">Gen OAuth2</option>
                                            <option value="TOTP">TOTP</option>
                                        </select>
                                    </div>
                                    {renderErrors(error, 'apiAccessType')}
                                </div>
                            ) : (

                                <div className="flex flex-wrap -mx-3 mb-3">
                                    <div className="w-1/2 md:w-2/5 px-3">
                                        <input value={key} name="key" onChange={(e) => setKey(e.target.value)} className="input input-bordered w-full" type="text" placeholder="Key" />
                                    </div>
                                    <div className="w-1/2 md:w-2/5 px-3">
                                        <div className="input-group">
                                            <input type={"text"} name="value" value={value} onChange={(e) => setValue(e.target.value)} placeholder="Value" className="input input-bordered w-full" />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {type === "APIAccess" && apiAccessType === "APIKEY" && (
                                <div>
                                    <div className="form-control w-full">
                                        <label className="label">
                                            <span className="label-text">API Key Placeholder</span>
                                        </label>
                                        <input type="text" placeholder="Provide a placeholder for API Key" value={apikey_placeholder} className="input input-bordered w-full" onChange={e => setapikey_placeholder(e.target.value)} />
                                        {renderErrors(error, 'apikey_placeholder')}
                                    </div>
                                    <div className="form-control w-full">
                                        <label className="label">
                                            <span className="label-text">Select API Key Placement</span>
                                        </label>
                                        <div className="input-group">
                                            <select value={apikey_placement} onChange={e => { setapikey_placement(e.target.value); }} className="input input-bordered w-full">
                                                <option value="header">Header</option>
                                                <option value="query">Query Parameter</option>
                                            </select>
                                        </div>
                                        {renderErrors(error, 'apikey_placement')}
                                    </div>
                                </div>

                            )}
                            {type === "APIAccess" && (apiAccessType === "CIDAAS_OAUTH2" || apiAccessType === "GEN_OAUTH2") && (
                                <div>
                                    <div className="form-control w-full">
                                        <label className="label">
                                            <span className="label-text">Client ID</span>
                                        </label>
                                        <input type="text" placeholder="Provide a Client ID" value={clientId} className="input input-bordered w-full" onChange={e => setClientId(e.target.value)} />
                                        {renderErrors(error, 'clientId')}
                                    </div>
                                    <div className="form-control w-full">
                                        <label className="label">
                                            <span className="label-text">Client Secret</span>
                                        </label>
                                        <input type="text" placeholder="Provide a Client Secret" value={clientSecret} className="input input-bordered w-full" onChange={e => setClientSecret(e.target.value)} />
                                        {renderErrors(error, 'clientSecret')}
                                    </div>
                                    <div className="form-control w-full">
                                        <label className="label">
                                            <span className="label-text">Scopes</span>
                                        </label>
                                        <input type="text" placeholder="Provide scopes" value={reqScopes} className="input input-bordered w-full" onChange={e => setReqScopes(e.target.value)} />
                                        {renderErrors(error, 'reqScopes')}
                                    </div>
                                    <div className="form-control w-full">
                                        <label className="label">
                                            <span className="label-text">wellKnown Url</span>
                                        </label>
                                        <input type="text" placeholder="Provide wellKnown Url" value={wellKnownUrl} className="input input-bordered w-full" onChange={e => setWellKnownUrl(e.target.value)} />
                                        {renderErrors(error, 'wellKnownUrl')}
                                    </div>
                                </div>
                            )}

                            {type === "APIAccess" && apiAccessType === "TOTP" && (
                                <div>
                                    <div className="form-control w-full">
                                        <label className="label">
                                            <span className="label-text">TOTP Key Placeholder</span>
                                        </label>
                                        <input type="text" placeholder="Provide a placeholder for TOTP Key" value={totp_placeholder} className="input input-bordered w-full" onChange={e => settotp_placeholder(e.target.value)} />
                                        {renderErrors(error, 'totp_placeholder')}
                                    </div>
                                    <div className="form-control w-full">
                                        <label className="label">
                                            <span className="label-text">Select TOTP Key Placement</span>
                                        </label>
                                        <div className="input-group">
                                            <select value={totp_placement} onChange={e => { settotp_placement(e.target.value); }} className="input input-bordered w-full">
                                                <option value="header">Header</option>
                                                <option value="query">Query Parameter</option>
                                            </select>
                                        </div>
                                        {renderErrors(error, 'totp_placement')}
                                    </div>
                                </div>
                            )}

                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">Active</span>
                                </label>
                                <input type="checkbox" className={`toggle toggle-lg ${active ? 'toggle-success' : ''}`} checked={active} onChange={(e) => setActive(e.target.checked)} />
                                {renderErrors(error, 'active')}
                            </div>

                        </div>
                        <div className="bg-base-200 px-4 py-3 text-right sm:px-6">
                            <Link to="/configurations" className="btn btn-ghost mr-2">Cancel</Link>
                            <button disabled={executing} type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="md:col-span-1">
                <ContextHelp page="create-configuration" />
            </div>
        </div>

    </Fragment>
}

export default NewConfiguration;